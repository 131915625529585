import axiosIns from '@/libs/axios'

export default {
  getAllPaymentCycle: sessionId => axiosIns.get(`accounting/payment-cycle/show/${sessionId}`),
  getSessionClass: sessionId => axiosIns.get(`accounting/payment-cycle/session-class/${sessionId}`),
  getMonths: sessionId => axiosIns.get(`accounting/payment-cycle/months/${sessionId}`),
  savePaymentCycle: params => axiosIns.post('accounting/payment-cycle/store', params),
  updatePaymentCycle: (updateId, params) => axiosIns.put(`accounting/payment-cycle/update/${updateId}`, params),
  secSession: () => axiosIns.get('accounting/payment-cycle/sec-session'),
  receivableUpdate: (updateId, params) => axiosIns.put(`accounting/payment-cycle/receivable-config-update/${updateId}`, params),
  receivableStore: params => axiosIns.post('accounting/payment-cycle/receivable-config-store', params),
  deleteReceivableConfig: deleteId => axiosIns.put(`accounting/payment-cycle/receivable-config-delete/${deleteId}`),
  deletePaymentCycle: deleteId => axiosIns.put(`accounting/payment-cycle/delete/${deleteId}`),
  reprocess: id => axiosIns.put(`accounting/payment-cycle/receivable-config-reprocess/${id}`),
  receivableConfig: id => axiosIns.get(`accounting/payment-cycle/receivable-config/${id}`),
}
