<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Payment Cycle
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <div>
        <b-form-group>
          <b-button
            variant="outline-primary"
            @click="openPaymentCycleModal"
          >
            Add Payment Cycle
          </b-button>
        </b-form-group>
      </div>
      <!-- Session Filter Dropdown -->
      <b-form-group
        label-for="pay-cycle-config-session"
        class="pr-0 col-lg-3 col-md-4"
      >
        <v-select
          v-model="paymentCycleSessionId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Session"
          :options="paymentCycleConfigSessionOptions"
          :reduce="(val) => val.id"
        />
      </b-form-group>
    </div>

    <!-- table for payment cycle  -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :line-numbers="true"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="px-1 mr-1"
              variant="outline-success"
              @click="receivableConfigPaymentCycle(props.row)"
            >Receivable Config</b-button>
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="openPaymentCycleModal(props.row)"
            >Edit</b-button>
            <b-button
              v-if="props.row.can_be_deleted"
              class="px-1 mr-1"
              variant="outline-danger"
              @click="deletePaymentCycle(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Months -->
        <span v-else-if="props.column.field === 'months'">
          {{ props.formattedRow[props.column.field].names }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Payment Cycle Config Modal -->
    <b-modal
      id="payment-cycle-modal"
      no-close-on-backdrop
      title="Payment Cycle Config"
      :ok-title="updateId ? 'Update' : 'Save'"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok.prevent="savePaymentCycle"
    >
      <b-form @submit.prevent>
        <!-- Payment Cycle -->
        <b-form-group
          label="Payment Cycle Name"
          label-cols-md="4"
          invalid-feedback="Payment Cycle Name is required"
          :state="paymentCycleNameState"
        >
          <b-form-input
            v-model="paymentCycleData.name"
            placeholder="Payment Cycle Name"
            :state="paymentCycleNameState"
            required
          />
        </b-form-group>

        <!-- Sorting -->
        <b-form-group
          label="Sorting"
          label-cols-md="4"
          invalid-feedback="Sorting is required"
          :state="sortingState"
        >
          <b-form-input
            v-model="paymentCycleData.sorting"
            placeholder="Sorting"
            :state="sortingState"
            :disabled="!paymentCycleData.canBeDeleted"
            required
          />
        </b-form-group>

        <!-- Months List -->
        <b-form-group
          label="Select Month"
          label-cols-md="4"
          invalid-feedback="Month is required"
          :state="monthState"
        >
          <b-row>
            <b-col>
              <b-form-checkbox-group
                v-model="paymentCycleData.months"
                :options="paymentCycleMonths"
                value-field="id"
                text-field="name"
                :state="monthState"
                required
                class="col-md-3"
                :disabled="!paymentCycleData.canBeDeleted"
              />
            </b-col>
          </b-row>
        </b-form-group>

      </b-form>
    </b-modal>

    <!-- Add Receivable Config Model  -->
    <b-modal
      id="add-receivable-config-model"
      no-close-on-backdrop
      size="xl"
      title="Add Receivable Config"
      ok-variant="outline-primary"
      ok-title="Close"
      ok-only
    >
      <b-form @submit.prevent>
        <b-row class="mt-2">
          <b-col class="d-flex">
            <b-form-group
              label="Campus"
              class="col-3"
            >
              <v-select
                v-model="pcReceivableConfigData.campuses"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="short_name"
                multiple
                :options="paymentCycleCampusOptions"
                placeholder="All Campuses"
                :reduce="val => val.id"
              />
            </b-form-group>
            <b-form-group
              label="Class"
              class="col-3"
            >
              <v-select
                v-model="pcReceivableConfigData.classes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                multiple
                :options="paymentCycleClassOptions"
                placeholder="All Classes"
                :reduce="val => val.id"
              />
            </b-form-group>
            <b-form-group
              label="Receivable Date"
              class="col-2"
            >
              <flat-pickr
                v-model="pcReceivableConfigData.receivableDate"
                class="form-control"
                placeholder="Receivable Date"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                required
              />
            </b-form-group>
            <b-form-group
              label="Last Date of Payment"
              class="col-2"
            >
              <flat-pickr
                v-model="pcReceivableConfigData.lastPaymentDate"
                class="form-control"
                placeholder="Last Payment Date"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                required
              />
            </b-form-group>
            <b-form-group
              label-sr-only
              label="Submit"
              class="col-4 align-self-end d-flex"
            >
              <b-button
                type="submit"
                variant="outline-primary"
                class="mr-1"
                @click.prevent="handlePaymentConfigChildFormSubmit"
              >
                <!-- Add -->
                {{ modalEditMode ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                type="submit"
                variant="outline-success"
                class="mr-1"
                @click.prevent="receivableConfigModelReset"
              >
                Reset
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- Table for payment cycle config -->
      <vue-good-table
        :columns="modalColumns"
        :rows="modalRows"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :line-numbers="true"
        theme="my-theme"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!--Column Status-->
          <span v-if="props.column.field === 'processStatus'">
            <span class="d-flex">
              <p v-if="props.row.process_status == 1">Success</p>
              <p v-else-if="props.row.process_status == 2">Failed</p>
              <p v-else>Pending</p>
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button
                v-if="props.row.is_deleted"
                class="mr-1 px-1"
                variant="outline-warning"
                @click="editPcConfig(props.row)"
              >Edit</b-button>
              <b-button
                v-if="props.row.is_deleted"
                class="px-1 mr-1"
                variant="outline-danger"
                @click.prevent="deletePcConfig(props.row)"
              >Remove</b-button>
              <b-button
                class="px-1 mr-1"
                variant="outline-info"
                @click.prevent="onReProcess(props.row.id)"
              >Re Process</b-button>
            </span>
          </span>
          <!--Column Receivable date-->
          <!-- <span v-if="props.column.field === 'receivable_date'">
            <span class="d-flex">
              <p>{{ new Date(receivable_date).toLocaleString("en-US", {timeZone: "Asia/Dhaka"}) }}</p>
            </span>
          </span> -->
          <span v-if="props.column.field === 'receivableDate'">{{
            props.row.receivable_date ? showLocalDateTime(props.row.receivable_date) : "N/A"
          }}</span>
          <span v-if="props.column.field === 'lastDateOfPayment'">{{
            props.row.last_date_of_payment ? showLocalDateTime(props.row.last_date_of_payment) : "N/A"
          }}</span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20','30','40','50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BPagination,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormCheckboxGroup,
  VBModal,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BLink,
  // BFormCheckbox,
  // BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaymentCycleService from '@/@service/api/accounting/PaymentCycle.service'
import StdFeeConfigService from '@/@service/api/accounting/StdFeeConfig.service'
import ActivityModal from '../audit/ActivityModal.vue'

// eslint-disable-next-line no-unused-vars
import { showLocalDateTime } from '@/@service/utils/utils'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckboxGroup,
    // BFormDatepicker,
    vSelect,
    BButton,
    BRow,
    BCol,
    flatPickr,
    RefreshCwIcon,

    BLink,
    ActivityModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      modalEditMode: false,
      updateId: null,
      formData: {},
      paymentCycleData: {
        name: null,
        months: [],
        sorting: null,
        canBeDeleted: true,
      },
      pcReceivableConfigData: {},
      pcConfigFinalData: [],
      receivableDate: new Date(),
      lastPaymentDate: new Date(),
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Payment Cycle Name',
          field: 'cycle_name',
        },
        {
          label: 'Sorting',
          field: 'sorting',
        },
        {
          label: 'Months',
          field: 'months.names',

        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      modalColumns: [
        {
          label: 'Campus',
          field: 'campusNames',
        },
        {
          label: 'Class',
          field: 'classNames',
        },
        {
          label: 'Receivable Date',
          field: 'receivableDate',
          sortable: false,
        },
        {
          label: 'Last Date of Payment',
          field: 'lastDateOfPayment',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'processStatus',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      modalRows: [],
      searchTerm: '',

      paymentCycleSessionId: null,
      paymentCycleCampuses: [],
      paymentCycleClasses: [],
      paymentCycleCampusOptions: [],
      paymentCycleClassOptions: [],
      paymentCycleMonths: [],
      paymentCycleConfigSessionOptions: [],

      // New
      paymentCycleNameState: null,
      sortingState: null,
      monthState: null,
      modalEditId: null,

      tag: 'payment-cycle',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    paymentCycleSessionId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPaymentCycleGridData()
      }
    },
  },
  created() {
    setTimeout(() => {
      this.getPaymentCycleSession()
    }, 500)
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.pcReceivableConfigData = {}
    })
  },
  methods: {
    getPaymentCycleGridData() {
      if (!this.paymentCycleSessionId) {
        this.rows = []
        return
      }
      PaymentCycleService.getAllPaymentCycle(this.paymentCycleSessionId).then(res => {
        this.rows = res.data.data
      })
    },
    refreshGrid() {
      this.getPaymentCycleGridData()
    },
    getPaymentCycleCampus() {
      StdFeeConfigService.allCampus().then(res => {
        this.paymentCycleCampusOptions = res.data.data
      })
    },
    getPaymentCycleClass() {
      if (!this.paymentCycleSessionId) {
        FemsToastrService.error('Session must be selected')
        return
      }
      PaymentCycleService.getSessionClass(this.paymentCycleSessionId).then(res => {
        this.paymentCycleClassOptions = res.data.data
      })
    },
    getPaymentCycleMonths() {
      if (!this.paymentCycleSessionId) {
        FemsToastrService.error('Session must be selected')
        return
      }
      PaymentCycleService.getMonths(this.paymentCycleSessionId).then(res => {
        this.paymentCycleMonths = res.data.data
      })
    },
    savePaymentCycle() {
      if (!this.checkFormValidity()) {
        return
      }

      const params = {
        secSessionId: this.paymentCycleSessionId,
        cycleName: this.paymentCycleData.name,
        sorting: this.paymentCycleData.sorting,
        months: JSON.stringify(this.paymentCycleData.months),
      }
      if (this.updateId) {
        PaymentCycleService.updatePaymentCycle(this.updateId, params).then(res => {
          if (res.status) {
            this.paymentCycleData = {}
            this.pcConfigFinalData = []
            this.getPaymentCycleGridData()
            this.$bvModal.hide('payment-cycle-modal')
            FemsToastrService.success(res?.data.message)
            return
          }
          FemsToastrService.error(res?.data.message)
        })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      } else {
        // eslint-disable-next-line no-unreachable
        PaymentCycleService.savePaymentCycle(params).then(res => {
          if (res.status) {
            this.paymentCycleData = {}
            this.pcConfigFinalData = []
            this.getPaymentCycleGridData()
            this.$bvModal.hide('payment-cycle-modal')
            FemsToastrService.success(res?.data.message)
            return
          }
          FemsToastrService.error(res?.data.message)
        })
          .catch(err => {
            FemsToastrService.error(err?.message)
          })
      }
    },
    editRow(rowData) {
      this.coaEditData = rowData
      this.updateId = rowData.id

      this.$bvModal.show('vc-config-edit-modal')
    },
    editPcConfig(rowData) {
      this.modalEditMode = true
      this.modalEditId = rowData.id
      this.modalEditParentId = rowData.parent_id
      this.pcReceivableConfigData = {
        campuses: rowData.campus_ids,
        classes: rowData.class_ids,
        receivableDate: rowData.receivable_date,
        lastPaymentDate: rowData.last_date_of_payment,
      }
    },
    openPaymentCycleModal(rowData) {
      this.resetForm()
      this.getPaymentCycleMonths()
      if (rowData && rowData.id) {
        this.updateId = rowData.id
        this.paymentCycleData.name = rowData.cycle_name
        this.paymentCycleData.sorting = rowData.sorting

        this.paymentCycleData.canBeDeleted = rowData.can_be_deleted
        this.paymentCycleData.months = rowData.months && rowData.months.ids
          ? JSON.parse(rowData.months.ids)
          : []
      }
      this.$bvModal.show('payment-cycle-modal')
    },

    getPaymentCycleSession() {
      PaymentCycleService.secSession().then(res => {
        if (res.data.status) {
          this.paymentCycleConfigSessionOptions = res.data.data
          this.paymentCycleSessionId = this.paymentCycleConfigSessionOptions.find(x => x.is_current === 1).id

          if (!this.paymentCycleSessionId) {
            FemsToastrService.error(res?.data.message)
          }
        }
      })
    },
    // eslint-disable-next-line consistent-return
    handlePaymentConfigChildFormSubmit() {
      let errorMessage = null
      if (!this.pcReceivableConfigData.classes || this.pcReceivableConfigData.classes === '') {
        errorMessage = 'Please enter Classes!'
      }
      if (!this.pcReceivableConfigData.campuses || this.pcReceivableConfigData.campuses === '') {
        errorMessage = 'Please enter Campuses'
      }
      if (
        !this.pcReceivableConfigData.receivableDate
        || this.pcReceivableConfigData.receivableDate === ''
      ) {
        errorMessage = 'Please enter receivable date!'
      }
      if (
        !this.pcReceivableConfigData.lastPaymentDate
        || this.pcReceivableConfigData.lastPaymentDate === ''
      ) {
        errorMessage = 'Please enter last date of payment!'
      }
      const d = new Date()
      let currDate = d.getDate()
      let currMonth = d.getMonth() + 1 // Months are zero based
      const currYear = d.getFullYear()
      if (currMonth < 10) {
        // eslint-disable-next-line no-const-assign
        currMonth = `0${currMonth}`
      }
      if (currDate < 10) {
        // eslint-disable-next-line no-const-assign
        currDate = `0${currDate}`
      }
      const currentDateTime = `${currYear}-${currMonth}-${currDate} 00:00`
      if (
        this.pcReceivableConfigData.receivableDate
        && this.pcReceivableConfigData.receivableDate < currentDateTime
      ) {
        errorMessage = 'Receivable Date can not be a previous date!'
      }
      if (errorMessage) {
        FemsToastrService.error(errorMessage)
        return
      }

      const params = {
        parent_id: this.modalEditParentId,
        campus_ids: JSON.stringify(this.pcReceivableConfigData.campuses),
        class_ids: JSON.stringify(this.pcReceivableConfigData.classes),
        receivable_date: this.pcReceivableConfigData.receivableDate,
        last_date_of_payment: this.pcReceivableConfigData.lastPaymentDate,
      }
      // Add or Update Operation will be here
      if (this.modalEditId !== null && this.modalEditMode === true) {
        PaymentCycleService.receivableUpdate(this.modalEditId, params).then(res => {
          if (res.status) {
            this.modalEditId = null
            this.modalEditMode = false
            this.pcReceivableConfigData = {}
            FemsToastrService.success(res.data.message)

            // Data load for grid view in pc config
            this.loadDataForReceivableGrid(this.modalEditParentId)
            return
          }
          FemsToastrService.error(res.data.message)
        })
          .catch(err => {
            FemsToastrService.error(err.message)
          })
      } else {
        PaymentCycleService.receivableStore(params).then(res => {
          if (res.status) {
            this.modalEditId = null
            this.modalEditMode = false
            this.pcReceivableConfigData = {}
            FemsToastrService.success(res.data.message)
            // Data load for grid view in pc config
            this.loadDataForReceivableGrid(this.modalEditParentId)
            return
          }
          FemsToastrService.error(res.data.message)
        })
          .catch(err => {
            FemsToastrService.error(err.message)
          })
      }
    },
    deletePcConfig(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PaymentCycleService.deleteReceivableConfig(rowData.id).then(res => {
            if (res.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Files Deleted successfully',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // Data load for grid view in pc config
              this.loadDataForReceivableGrid(this.modalEditParentId)
              return
            }
            FemsToastrService.error(res?.data.message)
          })
            .catch(err => {
              FemsToastrService.error(err?.message)
            })
        }
      })
    },
    deletePaymentCycle(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PaymentCycleService.deletePaymentCycle(rowData.id).then(res => {
            if (res.status && res.data.status) {
              this.getPaymentCycleGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              return
            }
            FemsToastrService.error(res?.data.message)
          })
        }
      })
    },
    onReProcess(id) {
      if (!id) {
        FemsToastrService.error('Receivable config not found')
        return
      }
      this.$swal({
        title: 'Are you sure to ReProcess?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          PaymentCycleService.reprocess(id).then(res => {
            if (res.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Updated!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // Data load for grid view in pc config
              this.loadDataForReceivableGrid(this.modalEditParentId)
              return
            }

            FemsToastrService.error(res?.data.message)
          })
            .catch(err => {
              FemsToastrService.error(err?.message)
            })
        }
      })
    },
    validateMonthsList(months) {
      return months
        .sort()
        .every((num, i) => (months[i + 1] || num + 1) - num === 1)
    },

    // New change For version 2
    resetForm() {
      this.modalEditMode = false
      this.updateId = null
      this.formData = {}
      this.paymentCycleData = {
        name: null,
        months: [],
        sorting: null,
        canBeDeleted: true,
      }
      this.pcReceivableConfigData = {}

      this.receivableDate = new Date()
      this.lastPaymentDate = new Date()
      // New
      this.paymentCycleNameState = null
      this.monthState = null
    },
    checkFormValidity() {
      this.paymentCycleNameState = null
      this.monthState = null
      if (
        this.paymentCycleData.name === ''
        || this.paymentCycleData.name === null
      ) {
        this.paymentCycleNameState = false
        return false
      }
      if (this.paymentCycleData.months.length < 1) {
        this.monthState = false
        return false
      }
      return true
    },
    receivableConfigPaymentCycle(rowData) {
      this.modalRows = []
      this.modalEditParentId = rowData.id
      this.getPaymentCycleCampus()
      this.getPaymentCycleClass()
      PaymentCycleService.receivableConfig(rowData.id).then(res => {
        if (res.status && res.data.status) {
          this.modalRows = res.data.data
        }
      })
      this.$bvModal.show('add-receivable-config-model')
    },

    loadDataForReceivableGrid(id) {
      PaymentCycleService.receivableConfig(id).then(response => {
        if (response.status && response.data.status) {
          this.modalRows = response.data.data
          return
        }

        FemsToastrService.error(response?.data.message)
      })
    },
    receivableConfigModelReset() {
      this.pcReceivableConfigData = {}
      this.modalEditMode = false
      this.updateId = null
      this.receivableDate = new Date()
      this.lastPaymentDate = new Date()
    },

    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
